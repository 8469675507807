import { Role } from '~/common/providers/auth/role';

/**
 * 設定
 */
export const settingsAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
  'IT_STAFF',
];

/**
 * 設定 > 会社設定
 */
export const settingsTenantAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'IT_STAFF',
];

/**
 * 設定 > 組織管理
 */
export const settingsOrganizationAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
  'IT_STAFF',
];

/**
 * 設定 > 科目管理
 */
export const settingsAccountTreeAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
];

/**
 * 設定 > 非財務科目管理
 */
export const settingsNonFinancialAccountsAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
];

/**
 * 設定 > 計算科目管理
 */
export const settingCalculatedAccountsAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
];

/**
 * 設定 > 分析軸管理
 */
export const settingsDimensionsAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
];

/**
 * 設定 > 計画管理
 */
export const settingsPlansAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
];

/**
 * 設定 > ユーザー管理
 */
export const settingsUsersAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'IT_STAFF',
];

/**
 * 設定 > 実績アップロード管理
 */
export const settingsImportActualsAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
];

export const settingsViewPermissionsAllowedRoles: Role[] = ['LOGLASS_SYSTEM_ADMIN', 'TENANT_ADMIN'];

/**
 * 設定 > 商品単価管理
 */
export const settingsUnitPricesAllowedRoles: Role[] = [
  'LOGLASS_SYSTEM_ADMIN',
  'TENANT_ADMIN',
  'CORP_PLANNING',
];
