import React, { memo } from 'react';
import { css } from '@emotion/react';
import Head from 'next/head';
import { ArrowLeft } from 'react-feather';
import { LoginUserInfoForAppLayout, Tenant } from '~/common/components/layout/AppLayout';
import { AppHeaderUserMenu } from '~/common/components/layout/app-header/AppHeaderUserMenu';
import { useHeaderInfo } from '~/common/components/layout/use-header-info';
import { Heading } from '~/common/components/heading';
import { Span } from '~/common/components/span';
import { ExternalLink, InternalLink } from '~/common/components/link';
import { Button } from '~/common/components/button';
import { openZendeskWidget } from '~/app/ZendeskWidgetProvider';
import { HStack } from '~/common/components/stack';
import { Divider } from '~/common/components/divider';
import { FeatureGuard } from '~/common/guards/FeatureGuard';
import { env } from '~/app/environment';

type AppHeaderProps = {
  loginUser: LoginUserInfoForAppLayout;
  onLogout: () => void;
  tenant: Tenant;
};

const AppHeader = memo(function AppHeader({ onLogout, loginUser, tenant }: AppHeaderProps) {
  const { headerInfo } = useHeaderInfo();
  const customerPortalUrl = env()?.customerPortal.url;

  return (
    <div css={styles.appHeader(headerInfo.backPageUrl != null)}>
      <Head>
        <title>{headerInfo.title}</title>
      </Head>

      {headerInfo.backPageUrl && (
        <div css={styles.pageTitleBackPageUrl}>
          <InternalLink href={headerInfo.backPageUrl}>
            <Button
              iconSize="m"
              variant="secondary"
              appearance="ghost"
              ariaLabel="return"
              icon={<ArrowLeft />}
            />
          </InternalLink>
        </div>
      )}
      <div id="headerTitle">
        {!headerInfo.editable && <Heading level={2.5}>{headerInfo.title}</Heading>}
      </div>

      <HStack gap="16px" justify="end" align="center">
        <button css={styles.textButton} onClick={handleInquiryClick}>
          <Span level={2} css={styles.supportSectionLabel}>
            お問い合わせ
          </Span>
        </button>

        {/* ポータルサイトへのアクセスについて
         * - ポータルサイトへのログインにはSalesforce側でのユーザアカウント作成が必要です
         * - 事前にユーザアカウントが作成されていない場合、ログインすることはできません
         * - ユーザアカウントの作成依頼はCREにご連絡ください
         */}
        {customerPortalUrl && (
          <FeatureGuard requiredFeatures={['isCustomerPortalEnabled']}>
            <ExternalLink href={customerPortalUrl} target={'_blank'} showIcon={true}>
              <Span level={2} css={styles.supportSectionLabel}>
                ポータルサイト
              </Span>
            </ExternalLink>
          </FeatureGuard>
        )}

        <ExternalLink href={SUPPORT_SITE_URL} target={'_blank'} showIcon={true}>
          <Span level={2} css={styles.supportSectionLabel}>
            サポートサイト
          </Span>
        </ExternalLink>

        <Divider color="gray" css={styles.divider} />

        <AppHeaderUserMenu loginUser={loginUser} onLogout={onLogout} tenant={tenant} />
      </HStack>
    </div>
  );
});

const SUPPORT_SITE_URL = 'https://support.loglass.com';

/**
 * お問い合わせをクリックするとzendesk widgetをアクティベートします
 */
const handleInquiryClick = openZendeskWidget;

const styles = {
  appHeader: (backNav: boolean) => css`
    display: grid;
    grid-template-columns: ${backNav ? 'min-content' : ''} 1fr max-content;
    align-items: center;
    padding: 0 var(--spacing-7);
    border-bottom: var(--border-width-s) solid var(--color-neutral-2);
    background-color: var(--color-base-background);
    height: 56px;
  `,
  textButton: css`
    background-color: transparent;
    cursor: pointer;
  `,
  divider: css`
    height: 20px;
    margin: 0 var(--spacing-2);
  `,
  supportSectionLabel: css`
    word-break: keep-all;
    white-space: nowrap;
  `,
  pageTitleBackPageUrl: css`
    cursor: pointer;
    margin-right: var(--spacing-5);
  `,
};

export { AppHeader };
export type { AppHeaderProps };
