import { createContext, ReactElement, ReactNode } from 'react';
import { ModalOptions } from '~/common/components/modal/ModalProvider';

type ModalContextProps = {
  openModal: (modal: ReactElement, options?: ModalOptions) => void;
  closeModal: () => void;
  children?: ReactNode;
};

const ModalContext = createContext<ModalContextProps>({
  openModal: () => undefined,
  closeModal: () => undefined,
});

export { ModalContext };
export type { ModalContextProps };
