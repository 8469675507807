import React, { ReactNode } from 'react';
import { EnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';
import { useFeatureGuard } from '~/common/guards/use-feature-guard';

type Props = {
  requiredFeatures: Array<keyof EnabledFeatureMap>;
  children: ReactNode;
};

/**
 * FeatureToggle を参照し、requiredFeatures 似指定したフラグがすべて有効の場合は children を表示する
 * 非同期通信中は null を返す
 **/
const FeatureGuard: React.FC<Props> = ({ requiredFeatures, children }) => {
  const enabled = useFeatureGuard(requiredFeatures);
  return <>{enabled && children}</>;
};

export { FeatureGuard };
