import { createContext, ReactNode } from 'react';
import { ToastProps } from '~/common/components/toast/Toast';

type ToastContextProps = {
  addToast: (toastProps: ToastProps) => void;
  children?: ReactNode;
};

const ToastContext = createContext<ToastContextProps>({
  addToast: () => undefined,
});

export { ToastContext };
export type { ToastContextProps };
