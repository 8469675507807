import React, { type ComponentProps, useCallback } from 'react';
import { useModal } from '~/common/components/modal/use-modal';
import { ConfirmModal } from '~/common/components/modal/ConfirmModal';

type Props = Omit<ComponentProps<typeof ConfirmModal>, 'closeModal'>;

type UseConfirmModal = {
  openConfirmModal: (props: Props) => void;
};

const useConfirmModal = (): UseConfirmModal => {
  const { openModal, closeModal } = useModal();

  const openConfirmModal = useCallback(
    (props: Props) => {
      openModal(<ConfirmModal {...props} closeModal={closeModal} />, {
        closeIfClickBackground: true,
      });
    },
    [closeModal, openModal],
  );

  return {
    openConfirmModal,
  };
};

export { useConfirmModal };
