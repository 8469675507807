import { ReactNode, memo } from 'react';
import { css } from '@emotion/react';
import { X } from 'react-feather';
import { Heading } from '~/common/components/heading';
import { Button } from '~/common/components/button';

type ModalHeaderProps = {
  onClose: () => void;
  title: string;
  children?: ReactNode;
  headingId?: string;
};

const ModalHeader: React.FC<ModalHeaderProps> = memo(function ModalHeader({
  onClose,
  title,
  children,
  headingId,
}) {
  return (
    <header css={styles.modalHeader}>
      <Heading level={3} id={headingId}>
        {title}
      </Heading>
      <div css={styles.content}>{children}</div>
      <Button
        variant="secondary"
        appearance="ghost"
        icon={<X />}
        ariaLabel={`「${title}」を閉じる`}
        onClick={onClose}
      />
    </header>
  );
});

const styles = {
  modalHeader: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-2);
    width: 100%;
    min-height: 56px;
    padding-left: var(--spacing-5);
    padding-right: var(--spacing-5);
    border-bottom: 1px solid var(--color-neutral-3);
    box-sizing: border-box;
    flex-shrink: 0;
    word-break: break-all;
  `,

  content: css`
    flex-grow: 1;
  `,
};

export { ModalHeader };
export type { ModalHeaderProps };
