import { SWRResponse } from 'swr';
import { ReqOptions } from './use-auth-req-options';
import { useFetch } from '~/common/hooks/use-fetch';
import { EnabledFeatureControllerApi, EnabledFeaturesDtoEnabledFeaturesEnum } from '~/common/api';

type EnabledFeatureMap = {
  isDashboardEnabled: boolean;
  isAnalyticsDataDownloadEnabled: boolean;
  isAllocationStandardBulkUploadEnabled: boolean;
  isTableauDashboardEnabled: boolean;
  isComparisonSheetSelectedConditionSaveEnabled: boolean;
  isActualItemDetailViewEnabled: boolean;
  isBulkPlanUpdateRequestEnabled: boolean;
  isComparisonSheetHighlightEnabled: boolean;
  isWorkforcePlanningEnabled: boolean;
  isNonFinancialActualCSVFormat: boolean;
  isAllocationByDimensions: boolean;
  isReportBigQueryEnabled: boolean;
  isDetailPopupInActualEnabled: boolean;
  isDimensionTreeEnabled: boolean;
  isAiComparisonAnalysisEnabled: boolean;
  isDisplayNoneDimensionEnabled: boolean;
  isViewPermissionEnabled: boolean;
  isAsyncAllocationExecutionEnabled: boolean;
  isFixedValueAllocationEnabled: boolean;
  isAiPlanActualAnalysisReportEnabled: boolean;
  isFinancialSheetHistoryAndDepartmentEnabled: boolean;
  isApiIntegrationEnabled: boolean;
  isWorkforceImportMappingSettingEnabled: boolean;
  isForeignCurrencyEnabled: boolean;
  isFinancialSimulatorEnabled: boolean;
  isRequestOfSlotAndConcurrentPostEnabled: boolean;
  isTmpAllocationFeatureForIneEnabled: boolean;
  isReportPolarsEnabled: boolean;
  isUnitPriceDataImportActualEnabled: boolean;
  isTransferDepartmentSlotForSimulatingEnabled: boolean;
  isCashFlowEnabled: boolean;
  isCustomerPortalEnabled: boolean;
};

const convertToFeatureMap = (
  enabledFeatures?: EnabledFeaturesDtoEnabledFeaturesEnum[],
): EnabledFeatureMap | undefined => {
  if (!enabledFeatures) return;

  const enabledFeatureMap: Map<EnabledFeaturesDtoEnabledFeaturesEnum, boolean> =
    enabledFeatures.reduce((acc, value) => acc.set(value, true), new Map());

  return {
    isDashboardEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.DevTableauDashboard,
    ),
    isAnalyticsDataDownloadEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.AnalyticsDataDownload,
    ),
    isAllocationStandardBulkUploadEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.AllocationStandardBulkUpload,
    ),
    isTableauDashboardEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.DevTableauDashboard,
    ),
    isComparisonSheetSelectedConditionSaveEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ComparisonSheetSelectedConditionSave,
    ),
    isActualItemDetailViewEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ActualItemDetailView,
    ),
    isAllocationByDimensions: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.AllocationByDimensions,
    ),
    isBulkPlanUpdateRequestEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.BulkPlanUpdateRequest,
    ),
    isComparisonSheetHighlightEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ComparisonSheetHighlight,
    ),
    isWorkforcePlanningEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.WorkforcePlanning,
    ),
    isNonFinancialActualCSVFormat: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.NonFinancialActualCsvFormat,
    ),
    isDetailPopupInActualEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.DetailPopupInActualReport,
    ),
    isDimensionTreeEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.DimensionTree,
    ),
    isAiComparisonAnalysisEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.LlmComparisonAnalysis,
    ),
    isDisplayNoneDimensionEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.DisplayNoneDimensionInReport,
    ),
    isViewPermissionEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ViewPermission,
    ),
    isReportBigQueryEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ReportBigquery,
    ),
    isAsyncAllocationExecutionEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.AsyncAllocationExecution,
    ),
    isFixedValueAllocationEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.FixedValueAllocation,
    ),
    isAiPlanActualAnalysisReportEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.AiPlanActualAnalysisReport,
    ),
    isFinancialSheetHistoryAndDepartmentEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.FinancialSheetHistoryAndDepartment,
    ),
    isApiIntegrationEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ApiIntegration,
    ),
    isWorkforceImportMappingSettingEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.WorkforceActualImportMappingSetting,
    ),
    isForeignCurrencyEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ForeignCurrency,
    ),
    isFinancialSimulatorEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.FinancialSimulator,
    ),
    isRequestOfSlotAndConcurrentPostEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.RequestOfSlotAndConcurrentPost,
    ),
    isTmpAllocationFeatureForIneEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.TmpAllocationFeatureForIne,
    ),
    isReportPolarsEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.ReportPolars,
    ),
    isUnitPriceDataImportActualEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.UnitPriceDataImportActual,
    ),
    isTransferDepartmentSlotForSimulatingEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.TransferDepartmentSlotForSimulation,
    ),
    isCashFlowEnabled: !!enabledFeatureMap.get(EnabledFeaturesDtoEnabledFeaturesEnum.CashFlow),
    isCustomerPortalEnabled: !!enabledFeatureMap.get(
      EnabledFeaturesDtoEnabledFeaturesEnum.CustomerPortal,
    ),
  };
};

/**
 * 有効化されているフィーチャーフラグのデータをMap形式で取得する
 */
const useEnabledFeatureMap = (): SWRResponse<EnabledFeatureMap | undefined, Error> => {
  const response = useEnabledFeatures();
  return {
    ...response,
    data: convertToFeatureMap(response.data),
    mutate: async () => {
      const result = await response.mutate();
      return convertToFeatureMap(result);
    },
  };
};

/**
 * 有効化されているフィーチャーフラグのデータを配列形式で取得する
 */
const useEnabledFeatures = (): SWRResponse<
  EnabledFeaturesDtoEnabledFeaturesEnum[] | undefined,
  Error
> => {
  return useFetch(
    FEATURES_CACHE_KEY,
    (reqOps) => fetcher(reqOps).then((response) => response.data.enabledFeatures),
    fetcherOptions,
  );
};

const FEATURES_CACHE_KEY = '/api/features' as const;
const fetcher = async (reqOps: ReqOptions) =>
  new EnabledFeatureControllerApi().listEnabledFeatures(reqOps);
const fetcherOptions = {
  dedupingInterval: 1000 * 60 * 60, // フィーチャーフラグはあまり変化しないので、1時間メモリ上のキャッシュに乗せる。リロードでリフレッシュする。
};

export { useEnabledFeatures, useEnabledFeatureMap };
export type { EnabledFeatureMap };
