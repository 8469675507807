import { useRef } from 'react';
import { useUnmount } from 'react-use';

type Props = {
  callback?: () => void;
  delay: number;
};

/**
 * setTimeout と clearTimeout を使いやすくするカスタムフック
 * メモリリークを防ぐために、コンポーネントがアンマウントされたときに clearTimeout を実行する
 */
const useTimeout = ({ callback: defaultCallback, delay }: Props) => {
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * タイマーをセットする関数
   * コールバックが指定されていない場合はデフォルトのコールバックを実行する
   */
  const setTimer = (callback?: () => void) => {
    if (callback == null && defaultCallback == null) return null;
    const id = setTimeout(() => {
      if (callback != null) callback();
      if (callback == null && defaultCallback != null) defaultCallback();
      clear(); // タイマーが実行されたら id をクリアする
    }, delay);
    timerIdRef.current = id;
    return id;
  };

  const clear = () => {
    if (timerIdRef.current == null) return;
    clearTimeout(timerIdRef.current);
    timerIdRef.current = null;
  };

  useUnmount(clear);

  return [setTimer, clear];
};

export { useTimeout };
