import { StatusType } from '@datadog/browser-logs';
import { httpErrorMessageMap } from '~/common/hooks/http-error/http-error-message-map';

type HttpError = Error & {
  message?: string;
  config?: {
    data?: 'MASKED';
    headers?: {
      authorization?: 'MASKED';
    };
  };
  response: {
    data?:
      | {
          message: string;
        }
      | '';
    status: number;
  };
};

type ALBTimeoutError = Error & {
  config?: {
    url?: string;
  };
};

const maskHttpError = (e: Error): Error => {
  const error = e as HttpError;

  if (error.config?.headers?.authorization != null) {
    error.config.headers.authorization = 'MASKED';
  }
  if (error.config?.data != null) {
    error.config.data = 'MASKED';
  }

  return error;
};
/**
 * HttpErrorへの型変換とBearerがはいるauthorization headerの値をマスクする
 * @param error
 */
const toHttpError = async (error: Error): Promise<HttpError | undefined> => {
  if (!isHttpError(error)) return;

  // ファイルダウンロード系のエラーの場合、Blobデータからテキストを抜き出しエラーメッセージとして扱う
  if (error?.response.data instanceof Blob) {
    return await convertErrorIfBlob(error);
  }
  return error;
};

const convertErrorIfBlob = async (error: unknown | undefined): Promise<HttpError | undefined> => {
  if (!isHttpError(error)) {
    return;
  }

  if (!(error?.response.data instanceof Blob)) {
    return error;
  }

  const blob = error.response.data;
  const text = await blob.text();
  error.response.data = JSON.parse(text);
  return error;
};

const isHttpError = (error: unknown | undefined): error is HttpError => {
  const httpError = error as Partial<HttpError>;
  return (
    typeof httpError !== 'undefined' &&
    typeof httpError.response !== 'undefined' &&
    typeof httpError.response.status !== 'undefined'
  );
};

const getErrorMessage = (httpError: HttpError): string => {
  if (httpError.response.data && httpError.response.data.message) {
    return httpError.response.data.message;
  }

  if (httpErrorMessageMap[httpError.response.status]) {
    return httpErrorMessageMap[httpError.response.status].default;
  }

  return httpErrorMessageMap.default;
};

const getDatadogAlertLevel = (httpError?: HttpError): StatusType => {
  // HttpErrorとして解析できないエラーならエラー
  if (!httpError) return StatusType.error;

  // HTTP系のエラーは基本WARNとする。バックエンドのエラーは別で監視をしているため。
  return StatusType.warn;
};

/**
 * NOTE:
 * 更新申請ファイル関連のエンドポイント位であるかどうかを判定する
 * 特定のテナントにおいてパフォーマンスが悪く、ALBのタイムアウトが発生するため、一時的にエラーメッセージを変更する
 *
 * https://www.notion.so/Loglass--1a5da7df8979811483e6d7aece3cda2e#1a5da7df897980408ac2d845b4fe2fd5
 */
const isPlanUpdateRequestFilesEndPoint = (url: string): boolean => {
  const ENDPOINTS = [
    '/download',
    '/downloadActual',
    '/checkExistsUnreflectableActual',
    '/downloadActualByDownloadPeriod',
  ] as const;

  return (
    !!url &&
    url.includes('/api/planUpdateRequestFiles/') &&
    ENDPOINTS.some((endpoint) => url.endsWith(endpoint))
  );
};

export type { HttpError, ALBTimeoutError };
export {
  toHttpError,
  getErrorMessage,
  getDatadogAlertLevel,
  maskHttpError,
  isPlanUpdateRequestFilesEndPoint,
};
