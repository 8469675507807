import React, { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useToast } from '~/common/components/toast/use-toast';
import { useLoginUser } from '~/common/providers/auth/use-login-user';
import { setDatadogLogsUserInfo } from '~/common/hooks/set-datadog-user-info';

type UseHandleBlockingErrorProps = {
  error?: Error;
  errorInfo?: React.ErrorInfo;
};

const useHandleBlockingError = ({ error, errorInfo }: UseHandleBlockingErrorProps): void => {
  const { addToast } = useToast();
  const loginUser = useLoginUser();

  useEffect(() => {
    if (error || errorInfo) {
      addToast({
        type: 'error',
        text: 'エラーが発生しました。ページを更新して再度お試しください。',
      });

      // Datadog に送信
      setDatadogLogsUserInfo(loginUser);
      if (errorInfo) {
        datadogLogs.logger.setContextProperty('componentStack', errorInfo.componentStack);
      }
      datadogLogs.logger.error(error?.message ?? '', {
        error: { name: error?.name, message: error?.message, stack: error?.stack },
      });
    }
  }, [error, addToast, errorInfo, loginUser]);
};

export { useHandleBlockingError };
