import { useCallback } from 'react';
import {
  getErrorMessage,
  HttpError,
  toHttpError,
  ALBTimeoutError,
  isPlanUpdateRequestFilesEndPoint,
} from '~/common/hooks/http-error/http-error';
import { useToast } from '~/common/components/toast/use-toast';
import { COMMON_ERROR_MSG } from '~/common/hooks/http-error/http-error-message-map';
import { useSendErrorToDataDog } from '~/common/hooks/use-send-error-to-data-dog';
import { useHandleUnauthorizedError } from '~/common/hooks/http-error/use-handle-unauthorized-error';
import { UseErrorHandling } from '~/common/hooks/error-handling/use-error-handling';

export type HandleHttpErrorOptions = {
  isDisplayToast: boolean;
};
type Return = {
  handleHttpError: ReturnType<UseErrorHandling>;
};

const DEFAULT_OPTIONS = {
  isDisplayToast: true,
} as const satisfies HandleHttpErrorOptions;

const useHandleHttpError = ({
  isDisplayToast,
}: HandleHttpErrorOptions = DEFAULT_OPTIONS): Return => {
  const { addToast } = useToast();
  const { sendErrorToDataDog } = useSendErrorToDataDog();
  const { handleUnauthorizedError } = useHandleUnauthorizedError();

  const handleHttpError = useCallback(
    async (e: unknown | undefined): Promise<void> => {
      if (e == null) {
        return;
      }

      if (isIgnoredError(e as Error)) {
        try {
          const url = (e as ALBTimeoutError).config?.url;
          if (isPlanUpdateRequestFilesEndPoint(url ?? '')) {
            addToast({
              type: 'error',
              text: '実行中の処理が中断されました。処理中にエラーが発生している可能性がございますので、お手数ですが管理者までお問い合わせください。',
            });
          } else {
            addToast({
              type: 'error',
              text: COMMON_ERROR_MSG,
            });
          }
        } catch {
          // as ALBTimeoutErrorにより実行時エラーが発生する可能性があるため、エラーハンドリングを行う
          addToast({
            type: 'error',
            text: COMMON_ERROR_MSG,
          });
          return;
        }
      }

      await sendErrorToDataDog(e as Error);

      const httpError = await toHttpError(e as Error);

      const errorMessage = httpError && getErrorMessage(httpError);
      if (errorMessage && isDisplayToast) {
        addToast({ type: 'error', text: errorMessage });
      }

      handleUnauthorizedError(httpError);
    },
    [addToast, handleUnauthorizedError, sendErrorToDataDog, isDisplayToast],
  );

  return { handleHttpError };
};

/**
 * ALBがタイムアウトした場合もここにマッチする
 */
const isIgnoredError = (error: Error) => {
  return error.message === 'Network Error';
};

/**
 * useFetch 用の handleHttpError を取得する
 * useFetch は共通化した UseErrorHandling 型を使うため、Property から関数を返す必要がある
 *
 * TODO: useHandleHttpError を UseErrorHandling 型にしてこの関数を削除する
 */
const useHandleHttpErrorForDefaultErrorHandling: UseErrorHandling = () => {
  const { handleHttpError } = useHandleHttpError();
  return handleHttpError;
};

export type { HttpError };
export { useHandleHttpError, isIgnoredError, useHandleHttpErrorForDefaultErrorHandling };
